import React, { Component } from 'react';

import { Container } from './styles';

export default class Footer extends Component{


    render(){
        return(
            <Container>
            </Container>
        );
    }

}