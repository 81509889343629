import React, { Fragment } from 'react'

import Header from '../../components/Header/'
import Equipment from '../../components/Equipment';
import PartnerSystems from '../../components/PartnerSystems';
import Footer from '../../components/Footer';

import LogoSvg from '../../assets/graphics/logo.svg';

import { BodyContainer, Body, Logo } from './styles';
import './styles.css'

const Home = () =>  (
    <Fragment>
        <Header />
        <BodyContainer>
            <Body className='inicio'>
                <Logo src={LogoSvg}/>
            </Body>
            <Body className='equipamentos'>
                <Equipment />
            </Body>
            <Body className='partnerSystems'>
                <PartnerSystems />
            </Body>
            <Body className='footer'>
                <Footer />
            </Body>
        </BodyContainer>
    </Fragment>
);

export default Home;