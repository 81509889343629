import React, { Component } from 'react';

import { Container, PartnerContainer, PartnerItems,PartnerItem, SystemsContainer, Title, SystemsItemContainer, SystemsItemTitle, SystemItemSubtitle, SystemItemContent,SystemItemContentItem } from './styles';

import logoBematech from '../../assets/graphics/logoBematech.svg';
import logoDNDES from '../../assets/graphics/logoBNDES.svg';
import logoDaruma from '../../assets/graphics/logoDaruma.svg';
import logoEligin from '../../assets/graphics/logoEligin.svg';

export default class PartnerSystems extends Component{


    render(){
        return (
            <Container>
                <PartnerContainer>
                    <Title>
                        Parceiros
                    </Title>
                    <PartnerItems>
                        <PartnerItem src={logoBematech}/>
                        <PartnerItem src={logoDNDES}/>
                        <PartnerItem src={logoDaruma}/>
                        <PartnerItem src={logoEligin}/>
                    </PartnerItems>
                </PartnerContainer>
                <SystemsContainer>
                    <SystemsItemContainer>
                        <SystemsItemTitle> Gerencial</SystemsItemTitle>
                        <SystemItemSubtitle> Supermercado</SystemItemSubtitle>
                        <SystemItemContent>
                            <SystemItemContentItem>Gerencia de compras e cotação</SystemItemContentItem>
                            <SystemItemContentItem>Gerencia de atualização de gôndola e promoções</SystemItemContentItem>
                            <SystemItemContentItem>Financeiro integrado</SystemItemContentItem>
                            <SystemItemContentItem>Contabilidade integrada</SystemItemContentItem>
                        </SystemItemContent>
                    </SystemsItemContainer>
                    <SystemsItemContainer>
                        <SystemsItemTitle> Infosac</SystemsItemTitle>
                        <SystemItemSubtitle> Pequenas empresas e lojas</SystemItemSubtitle>
                        <SystemItemContent>
                            <SystemItemContentItem>Controle de Estoque, compras e vendas</SystemItemContentItem>
                            <SystemItemContentItem>Controle fácilmente seu financeiro</SystemItemContentItem>
                            <SystemItemContentItem>NF-e e NFC-e integrado</SystemItemContentItem>
                            <SystemItemContentItem>Ordem de serviço</SystemItemContentItem>
                        </SystemItemContent>
                    </SystemsItemContainer>
                    <SystemsItemContainer>
                        <SystemsItemTitle> PDV</SystemsItemTitle>
                        <SystemItemSubtitle> Frente de loja</SystemItemSubtitle>
                        <SystemItemContent>
                            <SystemItemContentItem>Realize vendas fácilmente com emissão de NFC-e e NF-e integrado</SystemItemContentItem>
                            <SystemItemContentItem>Totalmente desconectado, suas vendas não param por falta de internet</SystemItemContentItem>
                            <SystemItemContentItem>Integrado com o financeiro e contabilidade</SystemItemContentItem>
                            <SystemItemContentItem>Fácil, confiável e rápido</SystemItemContentItem>
                        </SystemItemContent>
                    </SystemsItemContainer>
                </SystemsContainer>
            </Container>
        );
    }

}
