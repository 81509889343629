import styled from 'styled-components';

export const Container = styled.div`

    position: fixed;
    top: 0;
    height: 60px;
    width: 100%;
    z-index: 2;
    background-color: #000;

`;

export const ButtonBox = styled.div`

    position: absolute;
    right: 0;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    padding: 0px 15px;
`;

export const Shortcuts = styled.a`

    color: #FFF;
    line-height: 60px;
    font-size: 10px;
    padding: 0 12px;
    text-decoration: none;
    font-family: 'Halvetica', sans-serif;

    &:hover{
        cursor: pointer
    }

`;
