import styled from 'styled-components';


export const Container = styled.div`

    display: flex;
    flex-direction: column;
    margin: 100px 200px;

`;


export const EquipmentItem = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`;

export const EquipmentSubtitle = styled.h2`

    font-size: 14px;
    text-align: center;
    font-weight: 700;
    color: #000;

`;


export const EquipmentImage = styled.img`

    max-width: 50%;
    padding: 0px 20px;

`;

export const EquipmentsContainer = styled.div`

    display: flex;
    flex-direction: row;
    margin-top: 40px;
`;

export const Title = styled.h1`

    font-size: 28px;
    padding: 20px 0px 0px 0px;
    font-weight: 700;
    color: #000;

`;

export const Subtitle = styled.h2`

    font-size: 16px;
    padding: 20px 0 0 0;
    font-weight: 200;
    color: grey;

`;