import React, { Component } from 'react';

import { Container, ButtonBox, Shortcuts } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faChartArea, faPhone, faComments, faBuilding, faFileCode} from '@fortawesome/free-solid-svg-icons';

export default class Header extends Component{

    render(){
        return (
            <Container>
                <ButtonBox>
                    <Shortcuts href="#equipamentos"> <FontAwesomeIcon icon={faHome} /> EQUIPAMENTOS </Shortcuts>
                    <Shortcuts href="#sistemas"> <FontAwesomeIcon icon={faChartArea} /> SISTEMAS </Shortcuts>
                    <Shortcuts href="#contatos"> <FontAwesomeIcon icon={faPhone} rotation={90} /> CONTATOS </Shortcuts>
                    <Shortcuts href="http://168.205.104.18:8070/damonline"> <FontAwesomeIcon icon={faBuilding} /> DAM ONLINE </Shortcuts>
                    <Shortcuts href="http://168.205.104.18:8070/v3/crm/sistema/dam/empresa/dam/modulos"> <FontAwesomeIcon icon={faBuilding} /> CRM DAM </Shortcuts>
                    <Shortcuts href="http://168.205.104.18:8070/v3/crm/sistema/dam/empresa/vortex/modulos"> <FontAwesomeIcon icon={faBuilding} /> CRM VORTEX </Shortcuts>
                    <Shortcuts href="http://168.205.104.18:8070/v1/damgerenciador"> <FontAwesomeIcon icon={faBuilding} /> DAM GERENCIADOR </Shortcuts>
                    <Shortcuts href="http://168.205.104.18:8070/damcloud/contador"> <FontAwesomeIcon icon={faFileCode} /> CONSULTA XML </Shortcuts>
                    <Shortcuts href="http://168.205.104.18:8071/phpBB3"> <FontAwesomeIcon icon={faComments} /> FORUM DAM </Shortcuts>
                </ButtonBox>

            </Container>
        );
    }
}