import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import App from './App';


import Reset from './styles/Generic/Reset';
import Basic from './styles/Basic';

ReactDOM.render(
    <Fragment> 
        <Reset />
        <Basic />
        <App />
    </Fragment>
    ,document.getElementById('root'));
