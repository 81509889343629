import React, { Component } from 'react';

import { Title, Subtitle, Container, EquipmentsContainer, EquipmentItem, EquipmentImage, EquipmentSubtitle } from './styles';

import Balanca from '../../assets/graphics/balanca.jpg';
import Fiscal from '../../assets/graphics/fiscal.jpg';
import Gaveta from '../../assets/graphics/gaveta.jpg';

export default class Equipment extends Component{

    render(){
        return (
            <Container>

                <Title>Equipamentos</Title>
                <Subtitle> Nossos principais equipamentos</Subtitle>
                <EquipmentsContainer>
                    <EquipmentItem>
                        <EquipmentImage src={Balanca}/>
                        <EquipmentSubtitle>Balanças</EquipmentSubtitle>
                    </EquipmentItem>
                    <EquipmentItem>
                        <EquipmentImage src={Gaveta}/>
                        <EquipmentSubtitle>Gavetas eletronicas</EquipmentSubtitle>
                    </EquipmentItem>
                    <EquipmentItem>
                        <EquipmentImage src={Fiscal}/>
                        <EquipmentSubtitle>Impressoras fiscais</EquipmentSubtitle>
                    </EquipmentItem>
                </EquipmentsContainer>
            </Container>
        );
    }

}