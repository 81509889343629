import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

`;

export const PartnerContainer = styled.div`

    display: flex;
    flex-direction: row;
    height: 30%;
    background-color: #31475C;
    padding-left: 200px
    
`;

export const PartnerItems = styled.div`

    margin-left: 50px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    align-items: center;

`;

export const PartnerItem = styled.img`

    padding: 20px;
    align-self: baseline;
    max-width: 120px;
    max-height: 80px;
`;


export const SystemsContainer = styled.div`

    display: flex;
    flex-direction: row;
    height: 70%;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background-color: #F0F0F0;

`;

export const SystemsItemContainer = styled.div`

    display: flex;
    flex-direction: column;
    height: 80%;
    width: 20vw;
    margin-left: 20px;
    background-color: #FFF;
    border-radius: 10px;
    border-top: 10px solid #2C93E5;

`;

export const SystemsItemTitle = styled.h1`

    color: #000;
    font-size: 26px;
    font-weight: 700;
    text-align: right;
    padding-top: 10px;
    padding-right: 10px;

`;

export const SystemItemSubtitle = styled.h2`

    color: #808080;
    font-size: 12px;
    font-weight: 550;
    text-align: right;
    padding-right: 10px;
`;

export const SystemItemContent = styled.div`

    margin-top: 30px;
    padding: 20px;
    display: flex;
    flex-direction: column;

`;

export const SystemItemContentItem = styled.div`

    font-size: 16px;
    font-weight: 700;
    color: #808080;
    padding: 5px;

`;

export const Title = styled.h1`

    font-size: 28px;
    padding: 20px 0px 0px 0px;
    font-weight: 700;
    color: #F0F0F0;

`;
