import styled from 'styled-components';

export const BodyContainer = styled.div`

    display: flex;
    flex-direction: column;

`;

export const Logo = styled.img`

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5);

`;

export const Body = styled.div`

    height: 100vh
    width: 100%;

`;